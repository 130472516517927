<template>
  <div class="index">
    <div ref="header"><Header :bg="bg"></Header></div>
    <div class="toubu">
      <div class="text">
        产品创造价值
        <div class="bottom">
          汉康标准化产品，为客户提供及时、稳定、科学的选择和体验
        </div>
      </div>
    </div>

    <div class="newbox">
      <div class="top">了解汉康产品服务</div>
      <!-- <div class="more">
        提供多元化的管理及服务，增强城市服务管理能力，解决业务系统建设中的共性问题
      </div> -->
      <div class="list">
        <div
          class="son"
          v-for="(item, index) in newbox"
          :key="index"
          @mouseover="item.bool = true"
          @mouseout="item.bool = false"
        >
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
          <div class="gengduo" @click="goto(item)">
            了解更多<span class="el-icon-right"></span>
          </div>
          <div class="bankuai">
            <img
              class="img"
              :src="item.src"
              alt=""
              :class="item.bool ? 'ac' : ''"
            />
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>

    <div class="toback" ref="toback">
      <div class="toback-lianxi">
        <div class="toback-ph">
          <div class="toback-sons">
            <div class="toback-barss">
              <div class="toback-top">咨询电话</div>
              <div class="toback-bottom">133-3098-1239</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">技术支持</div>
              <div class="toback-bottom">189-8005-3442</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">售后电话</div>
              <div class="toback-bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="toback-wx">
          <div class="toback-son">
            <img src="@/assets/imgs/home/gongzonghao.png" alt />
            <div class="toback-tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
      <div class="toback-up" @click="up"></div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },

  data() {
    return {
      type: 1,
      list: [
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (1).png"),
          active: require("../../assets/imgs/home/big (1).png"),
          title: "物联网总线平台",
          type: 1,
        },
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (4).png"),
          active: require("../../assets/imgs/home/big (4).png"),
          title: "大数据平台",
          type: 2,
        },
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (2).png"),
          active: require("../../assets/imgs/home/big (2).png"),
          title: "智慧城市操作系统",
          type: 3,
        },
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (5).png"),
          active: require("../../assets/imgs/home/big (5).png"),
          title: "智慧城市可视化",
          type: 4,
        },
        {
          val: true,
          src: require("../../assets/imgs/home/smoll (3).png"),
          active: require("../../assets/imgs/home/big (3).png"),
          title: "智能设备采集",
          type: 5,
        },
      ],

      newbox: [
        {
          src: require("../../assets/imgs/chanping/1.png"),
          title: "智慧水务产品",
          text: "综合利用物联网、云计算、大数据、人工智能等先进技术，提升水利四预保障能力，加快推进水利现代化",
          goto: "/shuiwu",
          bool: false,
        },
        {
          src: require("../../assets/imgs/chanping/2.png"),
          title: "城市安全产品",
          text: "构建云+端的公共安全立体防控体系，实时动态监测预警、智慧化应用场景、响应应急处置",
          goto: "/chengshianquan",
          bool: false,
        },
        {
          src: require("../../assets/imgs/chanping/4.png"),
          title: "智慧城市产品",
          text: "利用汉康智慧城市操作系统促进城市的资源优化配置、社会管理精细有序、城市高效运行和可持续发展",
          goto: "/zhihui",
          bool: false,
        },
        {
          src: require("../../assets/imgs/chanping/5.png"),
          title: "智能设备产品",
          text: "智能设备为全域物联感知提供支撑",
          goto: "/shebei",
          bool: false,
        },
        {
          src: require("../../assets/imgs/chanping/3.png"),
          title: "智慧工具产品",
          text: "智慧工具为城市应用提供数据汇聚、处理、分析等全生命周期保障",
          goto: "/gongju",
          bool: false,
        },
      ],
      timer: null,
      arr: [],
      bg: null,
    };
  },
  methods: {
    active(item) {
      this.type = item.type;
    },
    up() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let sudu = 40;
      if (top >= 0 && top < 1000) {
        sudu = 40;
      } else if (top >= 1000 && top < 2000) {
        sudu = 60;
      } else if (top >= 2000 && top < 3000) {
        sudu = 100;
      } else if (top >= 3000 && top < 4000) {
        sudu = 140;
      } else {
        sudu = 200;
      }
      this.timer = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            sudu;

        if (top <= 0) {
          clearInterval(this.timer);
        }
      }, 10);

      // document.body.scrollTop = 0;
    },
    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.arr.push(scroll);
      //console.log("scroll :>> ", scroll);
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");
          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";

          if (scroll >= 470) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          // console.log("往上滑");
          if (scroll > 300) {
            this.$refs.header.style.position = "fixed";
            this.$refs.header.style.top = "0";
            this.$refs.header.style.left = "0";
            this.$refs.header.style.zIndex = "100";
            this.bg = "black";
          }
        }
        if (scroll > 120) {
          this.$refs.toback.style.opacity = "1";
        } else {
          this.$refs.toback.style.opacity = "0";
        }
        if (scroll <= 280) {
          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";
        }
      }
    },
    goto(item) {
      // this.$router.push(item.goto);

      this.$router.push(item.goto);
    },
  },

  mounted() {
    document.body.scrollTop = 0;

    window.addEventListener("scroll", this.scroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll, true);
  },
};
</script>

<style lang="scss" scoped>
.active {
  font-weight: 700;
  color: rgba(68, 112, 255, 1);
}

.index {
  width: 100%;
  height: 100%;
  background-color: rgba(27, 27, 27, 1);
  .toubu {
    width: 1920px;
    //height: 332px;
    height: 500px;
    background-image: url("../../assets/imgs/chanping/bg.png");
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    .text {
      // min-width: 462px;
      position: absolute;
      top: 180px;
      left: 343px;
      font-size: 60px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 84px;
      color: #ffffff;
      // border-bottom: 1px solid rgba(75, 75, 75, 1);
      // padding-bottom: 20px;
      .bottom {
        font-size: 20px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        line-height: 22px;
        color: #ffffff;
        margin-top: 8px;
      }
    }
  }
  .bar {
    width: 1920px;
    height: 84px;
    margin: 0 auto;
    background-color: rgb(245, 248, 255);
    padding: 0 260px;
    font-size: 18px;
    display: flex;
    .son {
      height: 100%;
      width: 228px;
      display: flex;
      align-items: center;
      color: rgba(162, 162, 162, 1);
      position: relative;
      cursor: pointer;
      font-size em {
        width: 50px;
        height: 2px;
        background-color: rgba(68, 112, 255, 1);
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
      }
    }
    .son:hover {
      color: rgba(68, 112, 255, 1);
      font-weight: 700;
    }
    .son:hover em {
      display: block;
    }
  }
  .nav {
    width: 1920px;
    padding: 50px 260px;
    display: flex;
    color: rgba(162, 162, 162, 1);
    margin: 0 auto;
    .son {
      width: 200px;
      height: 110px;
      border-bottom: 1px solid rgba(235, 235, 235, 1);
      position: relative;
      cursor: pointer;
      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
      }
      em {
        position: absolute;
        width: 40px;
        height: 2px;
        background-color: rgba(68, 112, 255, 1);
        bottom: -2px;
        left: 0;
      }
      .title {
        margin-top: 6px;
      }
    }
  }
  .zongxian {
    width: 1920px;
    height: 1000px;
    background-color: #fff;
    padding: 50px 260px;
    margin: 0 auto;
    .title {
      text-indent: 20px;
      color: rgba(51, 51, 51, 1);
      font-size: 20px;
      font-weight: 700;
      position: relative;
      em {
        width: 6px;
        height: 20px;
        background-color: rgba(68, 112, 255, 1);
        position: absolute;
        left: 2px;
        top: 4px;
      }
      .jieshao {
        text-indent: 0;
        font-size: 16px;
        color: rgba(136, 136, 136, 1);
        margin-top: 20px;
        font-weight: 400;
      }
    }
    .img {
      width: 1400px;
      height: 768px;
      margin-top: 50px;
    }
  }
  .shuju {
    background-color: rgba(248, 248, 248, 1);
    width: 1920px;
    height: 1150px;
    padding: 100px 260px;
    margin: 0 auto;
    .title {
      text-indent: 20px;
      color: rgba(51, 51, 51, 1);
      font-size: 20px;
      font-weight: 700;
      position: relative;
      em {
        width: 6px;
        height: 20px;
        background-color: rgba(68, 112, 255, 1);
        position: absolute;
        left: 2px;
        top: 4px;
      }
      .jieshao {
        text-indent: 0;
        font-size: 16px;
        color: rgba(136, 136, 136, 1);
        margin-top: 20px;
      }
    }
    .box {
      display: flex;
      margin-top: 50px;
      .son {
        width: 66px;
        height: 26px;
        position: relative;
        margin-right: 100px;
        .text {
          position: absolute;
          top: 0;
          right: -60px;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          color: rgba(51, 51, 51, 1);
        }
      }
      .one {
        background-color: rgba(191, 206, 255, 1);
      }
      .two {
        background-color: rgba(191, 255, 195, 1);
      }
      .three {
        background-color: rgba(255, 202, 191, 1);
      }
    }
    .biao {
      width: 1400px;
      height: 760px;
      margin-top: 30px;
    }
  }
  .zhihui {
    width: 1920px;
    height: 910px;
    padding: 100px 260px;
    background-color: #fff;
    margin: 0 auto;
    .title {
      text-indent: 20px;
      color: rgba(51, 51, 51, 1);
      font-size: 20px;
      font-weight: 700;
      position: relative;
      em {
        width: 6px;
        height: 20px;
        background-color: rgba(68, 112, 255, 1);
        position: absolute;
        left: 2px;
        top: 4px;
      }
      .jieshao {
        text-indent: 0;
        font-size: 16px;
        color: rgba(136, 136, 136, 1);
        margin-top: 20px;
      }
    }
    .chaozuo {
      width: 1400px;
      height: 540px;
      margin-top: 80px;
    }
  }
  .keshihua {
    width: 1920px;
    height: 882px;
    padding: 100px 260px;
    background-color: #fff;
    margin: 0 auto;
    .title {
      text-indent: 20px;
      color: rgba(51, 51, 51, 1);
      font-size: 20px;
      font-weight: 700;
      position: relative;
      em {
        width: 6px;
        height: 20px;
        background-color: rgba(68, 112, 255, 1);
        position: absolute;
        left: 2px;
        top: 4px;
      }
      .jieshao {
        text-indent: 0;
        font-size: 16px;
        color: rgba(136, 136, 136, 1);
        margin-top: 20px;
      }
    }
    .ke {
      width: 1400px;
      height: 540px;
      margin-top: 70px;
    }
  }
  .caiji {
    width: 1920px;
    height: 640px;
    padding: 100px 260px;
    background-color: #fff;
    position: relative;
    margin: 0;
    .title {
      text-indent: 20px;
      color: rgba(51, 51, 51, 1);
      font-size: 20px;
      font-weight: 700;
      position: relative;
      em {
        width: 6px;
        height: 20px;
        background-color: rgba(68, 112, 255, 1);
        position: absolute;
        left: 2px;
        top: 4px;
      }
    }
    .jieshao {
      width: 502px;
      //height: 272px;
      text-indent: 0;
      font-size: 16px;
      color: rgba(136, 136, 136, 1);
      margin-top: 40px;
    }
    .cj {
      width: 830px;
      height: 520px;
      position: absolute;
      right: 260px;
      top: 0;
    }
  }

  .newbox {
    width: 100%;
    background-color: rgba(27, 27, 27, 1);
    padding: 60px 260px;
    .top {
      width: 100%;
      font-size: 36px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;

      color: #ffffff;
      display: flex;
      justify-content: center;
    }
    .more {
      width: 100%;
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;

      color: #999999;
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }
    .list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 42px;
      .son {
        width: 685px;
        height: 253px;
        background: rgba(32, 39, 48, 1);

        opacity: 1;
        border-radius: 10px;
        margin-bottom: 26px;
        padding: 46px 40px;
        position: relative;
        .title {
          font-size: 24px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: 500;
          color: #ffffff;
        }
        .text {
          width: 376px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
          margin-top: 15px;
        }
        .gengduo {
          width: 106px;
          height: 38px;
          border: 1px solid rgba(255, 255, 255, 0.6);
          font-size: 12px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: 400;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.6);
          display: flex;
          align-items: center;
          justify-content: space-around;
          position: absolute;
          bottom: 36px;
          left: 40px;
          cursor: pointer;
        }
        .bankuai {
          width: 222px;
          height: 184px;
          background: url("../../assets/imgs/chanping/bankuai.png");
          background-size: 100% 100%;
          position: absolute;
          top: 35px;
          right: 20px;
          display: flex;
          justify-content: center;
          .img {
            width: 68px;
            height: 68px;
            margin-top: 34px;
          }
          .ac {
            animation: bounce-bottom 1.5s linear infinite;
          }
        }
        .gengduo:hover {
          color: #fff;
          border: 1px solid #ffffff;
        }
      }
      .son:hover {
        background-color: rgba(35, 44, 55, 1);
      }
    }
  }
}
@keyframes bounce-bottom {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
